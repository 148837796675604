<template>
  <div>
    <template v-if="user">
      <span v-if="websocket.status" style="color: green;"> | <i class="fa fa-heart"></i></span>
      <span v-else style="color: red; cursor: pointer;" @click="refresh_page()"> | <i class="fa fa-heart"></i></span>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import store from '@/store';
import { mapGetters } from 'vuex';
// import VersionService from '@/services/version';
// import auth from '@/auth';

export default {
  name: 'WisdomSocket',
  data () {
    return {
      quecue: [],
      all_in_one_list: [],
      rule_results: { rule_ignore: [], list: [], status: '' },
      refreshIntervalId: 0,
      user: JSON.parse(localStorage.getItem('user')),
      displayMediaOptions: {
        video: true,
        audio: true
      }
    };
  },
  state: {
    socket: {
      message: ''
    }
  },
  computed: mapGetters({
    websocket: 'websocket'
  }),
  notifications: {
    showNotification: { // You can have any name you want instead of 'showNotification'
      title: '',
      message: '',
      type: '' // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  },
  mounted: function () {
    if (this.$options.sockets) {
      this.$options.sockets.onopen = function (event) {
        console.log('websocket onopen..:::');
        Vue.prototype.$socket = event.target;
        event.target.send(JSON.stringify({ 'r_n': this.$route.name }));
        store.commit('ws_status', true);
        clearInterval(this.refreshIntervalId);
        // auth.last_wisdomera_version_control();
      };
      this.$options.sockets.onclose = function () {
        console.log('websocket onclose..:::');
        // Vue.prototype.$socket.close();
        store.commit('ws_status', false);
        // this.socket_not_connected();
      };
      this.$options.sockets.onerror = function () {
        console.log('websocket on error............');
        store.commit('ws_status', false);
        // console.error(state, event);
        // vm.status = 'Error!!';
      };
      this.$options.sockets.onmessage = function (message) {
        let socket_data = JSON.parse(message.data);
        if (socket_data.type === 'get_my_socket_id') {
          store.commit('websocket_id', { 'id': socket_data.your_socket_id });
        }

        if (socket_data.type === 'logout') {
          this.$router.push({ path: '/logout' });
        }
        if (socket_data.type === 'reload') {
          location.reload();
        }
        if (socket_data.type && socket_data.type === 'SendingText') {
          this.showNotification({ title: 'Sistem Mesajı', message: socket_data.data.SendingText, type: 'success', timeout: 10000 });
        }
        /*      if (['decision_making_system'].indexOf(socket_data.type) !== -1) {
                this.rule_results = socket_data.data;
                console.log('rule sonucu geldi', this.rule_results);
                if (this.rule_results.list.length > 0) {}
                let rule_message = this.rule_results.list.length.toString() + ' adet anlamlı algoritma tespit edildi.';
                this.showNotification({title: 'Karar Destek Sistemi', message: rule_message , type: 'success', timeout: 3000});
              } */
        if (socket_data.controller && socket_data.controller === 'none') {
          if (this.$route.name !== 'logout') {
            this.$router.push({ path: '/logout' });
          }
        }
      };
    }
  },
  methods: {
    refresh_page: function () {
      location.reload();
    }
  },
  watch: {},
  components: {}
};

</script>

<style type="text/css">
div.rule_engine {
  position: fixed;
  bottom: 30px;
  right: 0px;
  width: 200px;
  padding: 3px;
  box-shadow: -2px -2px;
  border-radius: 10px;
  /*border: 3px solid #73AD21;*/
  background-color: #20a8d8;
}

</style>

